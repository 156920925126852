export default class ConfirmationService {
  flag = true;
  getFlagValue() {
    return this.flag;
  }

  setFlagValue(val: boolean) {
    this.flag = val;
  }
}
