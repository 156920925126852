
import router from '@/router';
import TaxService from '../services/TaxService';
import ItemService from '../services/ItemService';
import AuditTrailTable from '@/components/AuditTrailTable.vue';
import SearchService from '@/services/SearchService';
import LocalStorageService from '@/services/LocalStorageService';
import RoleService from '../services/RoleService';

import { mapGetters } from 'vuex';

import ConfirmationService from '../services/ConfirmationService';

import { sortBy, isEmpty, isEqual } from 'lodash';
import Location from '@/Interfaces/Location';

import { toRaw } from 'vue';
import { defineComponent } from 'vue';
import Item from '@/Interfaces/Item';
import Tax from '@/Interfaces/Tax';

interface ComponentData {
  activateNotification: boolean;
  typeNotification: 'success' | 'error';
  titleNotification: string;
  messageNotification: string;
  searchTimeout: number;
  taxService: TaxService;
  itemService: ItemService;
  searchService: SearchService;
  roleService: RoleService;
  localStorageService: LocalStorageService;
  confirmationService: ConfirmationService;
  formErro: Record<string, any>;
  isEditMode: boolean;
  taxAction: string;
  showAuditTable: boolean;
  changesQueried: boolean;
  displayDefaultConfirmation: boolean;
  displayDefaultConfirmationService: boolean;
  goodsChecked: boolean;
  serviceChecked: boolean;
  taxRecord: [];
  taxAlreadyExistedGoods: boolean;
  taxAlreadyExistedService: boolean;
  taxAlreadyExistedServiceName: string;
  taxAlreadyExistedGoodsName: string;
  subMessageGoods: string;
  subMessageService: string;
  selectedLocations: string[];
  allLocationsSelected: boolean;
  allItemsSelected: boolean;
  selectAll: boolean;
  selectAllItems: string;
  applyAllServices: string;
  applyAllGoods: string;
  selectApplyToSpecificItems: string;
  allLocations: Location[];
  isDisabled: boolean;
  searchTerm: string;
  filteredLeftItems: Tax[];
  filteredRightItems: Tax[];
  leftItems: Tax[];
  rightItems: Tax[];
  selectedItems: Tax[];
  allItems: Tax[];
  loading: boolean;
  isSuperadminLoc: boolean;
  pageCount: number;
  pageFlag: boolean;

  isPopupBlock: string;
  alertTitle: string;
  alertMessage: string;
  showConfirm: boolean;

  setDiscardChangesModal: boolean;
  showDiscardChangesModal: boolean;

  breadcrumbs: Array<{
    text: string;
    href?: string;
    active?: boolean;
  }>;

  tax: {
    id: string;
    name: string;
    value: string;
    applyToAllGoods: boolean;
    applyToAllServices: boolean;
    applyToSpecificItems: boolean;
    taxLocations: Location[];
    items: Item[];
  };

  taxOriginalRef: Tax;
}

export default defineComponent({
  name: 'TaxDetail',
  components: {
    AuditTrailTable,
  },
  data() {
    const data: ComponentData = {
      activateNotification: false,
      typeNotification: 'success',
      titleNotification: '',
      messageNotification: '',
      searchTimeout: undefined,
      taxService: undefined,
      itemService: undefined,
      searchService: undefined,
      roleService: undefined,
      localStorageService: undefined,
      confirmationService: undefined,
      formErro: {},
      isEditMode: false,
      taxAction: 'Create',
      showAuditTable: false,
      changesQueried: false,

      selectedLocations: [],
      allLocationsSelected: true,
      allItemsSelected: false,
      selectAll: false,
      selectAllItems: '',
      applyAllServices: '',
      applyAllGoods: '',
      selectApplyToSpecificItems: '',
      allLocations: [],
      isDisabled: false,
      searchTerm: '',
      filteredLeftItems: [],
      filteredRightItems: [],
      leftItems: [],
      rightItems: [],
      selectedItems: [],
      allItems: [],
      loading: false,
      isSuperadminLoc: false,
      pageCount: 1,
      pageFlag: true,

      isPopupBlock: 'none',
      alertTitle: '',
      alertMessage: '',
      showConfirm: false,

      setDiscardChangesModal: false,
      showDiscardChangesModal: false,

      breadcrumbs: [
        {
          text: 'Taxes',
          href: '#/taxes',
        },
        {
          text: 'Create Tax',
          active: true,
        },
      ],

      tax: {
        id: '',
        name: '',
        value: '',
        applyToAllGoods: false,
        applyToAllServices: false,
        applyToSpecificItems: false,
        taxLocations: [],
        items: [],
      },

      taxOriginalRef: undefined,
      displayDefaultConfirmation: false,
      displayDefaultConfirmationService: false,
      goodsChecked: false,
      serviceChecked: false,
      taxRecord: [],
      taxAlreadyExistedGoods: false,
      taxAlreadyExistedService: false,
      taxAlreadyExistedServiceName: '',
      taxAlreadyExistedGoodsName: '',
      subMessageGoods: '',
      subMessageService: '',
    };
    return data;
  },
  created() {
    this.taxService = new TaxService();
    this.itemService = new ItemService();
    this.searchService = new SearchService();
    this.roleService = new RoleService(new LocalStorageService());
    this.localStorageService = new LocalStorageService();
    this.confirmationService = new ConfirmationService();
  },
  async mounted() {
    // Vue.prototype.actionPerform = false
    // Vue.prototype.changeTax = false
    this.loading = true;
    const allTaxesPromise = await this.taxService.getAllTaxes();
    this.taxRecord = allTaxesPromise.data.taxes;

    this.taxAlreadyExistedGoods = this.taxRecord.some(
      (element) => element.applyToAllGoods,
    );

    this.taxAlreadyExistedService = this.taxRecord.some(
      (element) => element.applyToAllServices,
    );

    if (this.taxAlreadyExistedService) {
      const matchingElement = this.taxRecord.find(
        (element) => element.applyToAllServices,
      );
      this.taxAlreadyExistedServiceName = matchingElement
        ? matchingElement.name
        : null;
      this.subMessageService = `You Currently have ${this.taxAlreadyExistedServiceName} default taxes`;
    }
    if (this.taxAlreadyExistedGoods) {
      const matchingElement = this.taxRecord.find(
        (element) => element.applyToAllGoods,
      );
      this.taxAlreadyExistedGoodsName = matchingElement
        ? matchingElement.name
        : null;
      this.subMessageGoods = `You Currently have ${this.taxAlreadyExistedGoodsName} default taxes`;
    }
    this.isSuperadminLoc = false;
    const taxId = this.$route.params.id;
    const locIdforSA = this.$route.query.selectedLocationId;
    await this.$store.dispatch('getLocationList');
    const locationResp = this.$store.getters.allLocationList;
    const locationOfSuperadmin = this.localStorageService.getItem('locations');
    if (
      locationOfSuperadmin &&
      this.roleService.isSuperadmin &&
      locIdforSA === locationOfSuperadmin.slice(2, -2)
    ) {
      this.allLocations = locationResp.filter(
        (l: any) => l.id === locationOfSuperadmin.slice(2, -2),
      );
    } else if (
      locIdforSA &&
      locationOfSuperadmin &&
      this.roleService.isSuperadmin &&
      locIdforSA !== locationOfSuperadmin.slice(2, -2)
    ) {
      this.isSuperadminLoc = true;
      this.allLocations = locationResp;
    } else if (locationOfSuperadmin && this.roleService.isSuperadmin) {
      this.allLocations = locationResp.filter(
        (l: any) => l.id === locationOfSuperadmin.slice(2, -2),
      );
    } else {
      this.allLocations = locationResp;
    }

    this.setIsEditMode(taxId);

    if (this.isEditMode) {
      this.taxAction = 'Edit';
      this.breadcrumbs[1].text = 'Edit Tax';
    }

    this.findOrInitializeAllLocationTaxes();
    if (this.isEditMode) {
      const taxPromise = await this.taxService.getTaxWithItemsById(taxId);
      taxPromise.data.tax.value = (taxPromise.data.tax.value * 100).toFixed(2);
      this.tax = taxPromise.data.tax;
      this.selectedLocations = this.tax.taxLocations
        .filter((taxLocation: any) => taxLocation.isActive)
        .map((taxLocation: any) => taxLocation.location!.id);

      this.selectedItems = this.tax.items.map((item: any) => item.id);
    } else {
      this.selectedLocations = this.allLocations.map(
        (location: any) => location.id,
      );
    }
    if (this.selectedLocations.length) {
      this.conductItemFilterForLocation(this.selectedLocations);
    }
    if (this.tax.applyToSpecificItems) {
      const itemResp = await this.itemService.getAllItemsFor(
        1,
        150,
        '',
        '',
        this.selectedLocations,
      );
      this.allItems = itemResp.data.items.filter((item: any) => {
        return item.itemType !== 3;
      });

      // const halflength = Math.ceil(this.allItems.length / 2);
      const leftItems = this.allItems; //.slice(0, halflength);
      // const rightItems = this.allItems.slice(halflength, this.allItems.length);
      this.filteredLeftItems = sortBy(leftItems, (o: any) => o.name);
      // this.filteredRightItems = sortBy(rightItems, (o: any) => o.name);
      this.leftItems = this.filteredLeftItems;
      // this.rightItems = this.filteredRightItems;
      const totalItemsCount = itemResp.data.totalCount;
      if (this.tax.items.length == totalItemsCount) {
        this.selectAll = true;
        this.selectedItems = [];
        this.selectedItems = this.leftItems.map((items: any) => items.id);
      } else {
        this.selectedItems = this.tax.items.map((items: any) => items.id);
      }
    }
    this.selectedItems = [...new Set(this.selectedItems)];
    this.loading = false;
    this.taxOriginalRef = {
      ...this.tax,
      taxLocations: [...this.tax.taxLocations],
      items: [...this.tax.items],
    };
  },
  methods: {
    formatTotal(total: number) {
      return (total * 1).toFixed(2);
    },

    validateNumberInput(event: any) {
      const value = event.target.value;
      const validValue = value
        .replace(/[^0-9.]/g, '')
        .replace(/(\..*)\./g, '$1');
      this.inputValue = validValue;
    },

    toggleSelectAll(event: any) {
      if (event.target.checked) {
        this.selectAllItems = 'add';
        this.selectedItems = [];
        this.selectedItems = this.leftItems.map((items: any) => items.id);
        this.selectedItems = [...new Set(this.selectedItems)];
      } else {
        this.selectAllItems = 'remove';
        this.selectedItems = [];
      }
    },

    async toggleApplyToSpecificItems(event: any) {
      this.allItems = [];
      const leftItems = this.allItems;
      this.filteredLeftItems = sortBy(leftItems, (o: any) => o.name);
      this.leftItems = this.filteredLeftItems;
      this.selectedItems = [];

      if (event.target.checked) {
        if (this.selectedLocations.length > 0)
          await this.getItemsForLocations(true);
      } else {
        this.selectApplyToSpecificItems = 'remove';
        this.selectedItems = [];
      }
    },

    async getItemsForLocations(toggleApplyToSpecificItems = false) {
      this.selectApplyToSpecificItems = 'add';
      if (toggleApplyToSpecificItems) {
        this.tax.applyToAllGoods = false;
        this.tax.applyToAllServices = false;
      }
      this.loading = true;
      const itemResp = await this.itemService.getAllItemsFor(
        1,
        150,
        '',
        '',
        this.selectedLocations,
      );
      this.allItems = itemResp.data.items.filter((item: any) => {
        return item.itemType !== 3;
      });
      const leftItems = this.allItems;
      this.filteredLeftItems = sortBy(leftItems, (o: any) => o.name);
      this.leftItems = this.filteredLeftItems;
      this.selectedItems = [];
      this.loading = false;
    },

    defaultConfirmedForGoods() {
      this.taxAlreadyExistedGoods = this.taxRecord.some(
        (element) => element.applyToAllGoods,
      );
      if (this.taxAlreadyExistedGoods) {
        const matchingElement = this.taxRecord.filter(
          (element) => element.applyToAllGoods,
        );
        let matchedItem = false;
        let numberOfTaxLoc = 0;
        matchingElement.forEach((checkMatch) => {
          checkMatch.taxLocations.forEach((taxLoc) => {
            if (this.selectedLocations.includes(taxLoc.location.id)) {
              matchedItem = true;
              numberOfTaxLoc++;
              this.taxAlreadyExistedGoodsName = checkMatch
                ? checkMatch.name
                : null;
              this.subMessageGoods = `You currently have ${numberOfTaxLoc} default taxes`;
            }
          });
        });
        if (
          !matchedItem ||
          !this.selectedLocations ||
          this.selectedLocations.length == 0
        ) {
          this.taxAlreadyExistedGoodsName = '';
          this.subMessageGoods = '';
          numberOfTaxLoc = 0;
        }
      }
      if (!this.goodsChecked) {
        this.displayDefaultConfirmation = true;
      }
      this.goodsChecked = false;
    },

    defaultConfirmedForService() {
      this.taxAlreadyExistedService = this.taxRecord.some(
        (element) => element.applyToAllServices,
      );
      if (this.taxAlreadyExistedService) {
        const matchingElement = this.taxRecord.filter(
          (element) => element.applyToAllServices,
        );
        let matchedItem = false;
        let numberOfTaxLoc = 0;
        matchingElement.forEach((checkMatch) => {
          checkMatch.taxLocations.forEach((taxLoc) => {
            if (this.selectedLocations.includes(taxLoc.location.id)) {
              matchedItem = true;
              numberOfTaxLoc++;
              this.taxAlreadyExistedServiceName = checkMatch
                ? checkMatch.name
                : null;
              this.subMessageService = `You currently have ${numberOfTaxLoc} default taxes`;
            }
          });
        });
        if (
          !matchedItem ||
          !this.selectedLocations ||
          this.selectedLocations.length == 0
        ) {
          this.taxAlreadyExistedServiceName = '';
          this.subMessageService = '';
          numberOfTaxLoc = 0;
        }
      }

      if (!this.serviceChecked) {
        this.displayDefaultConfirmationService = true;
      }
      this.serviceChecked = false;
    },

    cancelApplyAllGoods() {
      this.displayDefaultConfirmation = false;
      this.applyAllGoods = 'remove';
      this.tax.applyToAllGoods = false;
      this.goodsChecked = false;
      this.taxAlreadyExistedGoodsName = '';
    },

    confirmApplyAllGoods() {
      this.displayDefaultConfirmation = false;
      this.applyAllGoods = 'add';
      this.tax.applyToAllGoods = true;
      this.tax.defaultTaxExistedGoods = this.taxAlreadyExistedGoods;
      this.goodsChecked = true;
    },

    cancelApplyAllService() {
      this.displayDefaultConfirmationService = false;
      this.applyAllServices = 'remove';
      this.tax.applyToAllServices = false;
      this.serviceChecked = false;
      this.taxAlreadyExistedServiceName = '';
    },

    confirmApplyAllService() {
      this.displayDefaultConfirmationService = false;
      this.applyAllServices = 'add';
      this.tax.applyToAllServices = true;
      this.tax.defaultTaxExistedService = this.taxAlreadyExistedService;
      this.serviceChecked = true;
    },

    // toggleApplyAllGoods(event: any) {
    //   if (event.target.checked) {
    //     this.applyAllGoods = "add";
    //     this.tax.applyToSpecificItems = false;
    //   } else {
    //     this.applyAllGoods = "remove";
    //   }
    // }

    // toggleApplyAllServices(event: any) {
    //   if (event.target.checked) {
    //     this.applyAllServices = "add";
    //     this.tax.applyToSpecificItems = false;
    //   } else {
    //     this.applyAllServices = "remove";
    //   }
    // }

    async toggleAll(checked: []) {
      this.selectedLocations = this.allLocationsSelected
        ? this.allLocations.map((location: any) => location.id).slice()
        : [];
      await this.conductItemFilterForLocation(this.selectedLocations);
    },

    toggleAllItems(checked: []) {
      this.selectedItems = checked
        ? this.allItems.map((items) => items.id).slice()
        : [];
    },

    changeTax() {
      // Vue.prototype.changeTax = true
    },

    toggleOne(newVal: any[]) {
      if (newVal.length === this.allLocations.length) {
        this.allLocationsSelected = true;
      } else {
        this.allLocationsSelected = false;
      }
    },

    async toggleLocation(newVal: any) {
      if (this.selectedLocations.length === this.allLocations.length) {
        this.allLocationsSelected = true;
      } else {
        this.allLocationsSelected = false;
      }
      await this.conductItemFilterForLocation(this.selectedLocations);
    },

    toggleItems(newVal: any[]) {
      if (newVal.length === this.allItems.length) {
        this.allItemsSelected = true;
      } else {
        this.allItemsSelected = false;
      }
    },

    scrollFunction(obj: any) {
      const scrollHeight = document.getElementById('scrollDiv')?.scrollHeight;
      const clientHeight = document.getElementById('scrollDiv')?.clientHeight;
      const scrollTop = document.getElementById('scrollDiv')?.scrollTop;
      let scrollHeightNumber = 0;
      let scrollTopNumber = 0;
      if (scrollHeight && clientHeight && scrollTop) {
        scrollHeightNumber = scrollHeight - clientHeight;
        scrollTopNumber = scrollTop;
      }

      const topNeve = document.getElementById('scrollDiv');
      if (this.pageFlag && topNeve) {
        // if((scrollTopNumber > (scrollHeightNumber-500) && scrollTopNumber < (scrollHeightNumber-450)) && scrollHeightNumber != 0){
        if (
          scrollTopNumber > scrollHeightNumber - 10 &&
          scrollHeightNumber != 0
        ) {
          // topNeve.scrollTop = 100
          this.pageFlag = false;
          this.pageCount++;
          this.conductSearch();
        }
      }
    },

    searchByText() {
      this.leftItems = [];
      this.pageFlag = false;
      this.pageCount = 1;
      if (this.selectedLocations.length > 0) this.conductSearch();
    },

    async searchByTextDebouncing() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      // Search starts when user not pressed any key for 1000ms
      this.searchTimeout = setTimeout(() => {
        this.searchByText();
      }, 1000);
    },

    async conductSearch() {
      // this.leftItems = this.searchService.searchAndFilterItems(sortBy(this.filteredLeftItems, (o: any) => o.name), this.searchTerm)
      // this.rightItems = this.searchService.searchAndFilterItems(sortBy(this.filteredRightItems, (o: any) => o.name), this.searchTerm)
      this.loading = true;
      if (this.searchTerm) {
        const itemResp = await this.itemService.getAllItemsFor(
          this.pageCount,
          1000,
          this.searchTerm,
          '',
          this.selectedLocations,
        );
        this.allItems = itemResp.data.items.filter((item: any) => {
          return item.itemType !== 3;
        });
        // const halflength = Math.ceil(this.allItems.length / 2);
        // const leftItems = this.allItems//.slice(0, halflength);
        // const rightItems = this.allItems.slice(halflength, this.allItems.length);
        this.filteredLeftItems = sortBy(this.allItems, (o: any) => o.name);
        // this.filteredRightItems = sortBy(rightItems, (o: any) => o.name);
        this.leftItems = [...this.leftItems, ...this.filteredLeftItems];
        // this.rightItems = this.filteredRightItems;
        if (this.selectAll) {
          this.selectedItems = [];
          this.selectedItems = this.leftItems.map((items: any) => items.id);
        }

        if (this.leftItems.length < itemResp.data.totalCount) {
          this.pageFlag = true;
        }
      } else {
        const itemResp = await this.itemService.getAllItemsFor(
          this.pageCount,
          200,
          '',
          '',
          this.selectedLocations,
        );
        this.allItems = itemResp.data.items.filter((item: any) => {
          return item.itemType !== 3;
        });
        // const halflength = Math.ceil(this.allItems.length / 2);
        // const leftItems = this.allItems//.slice(0, halflength);
        // const rightItems = this.allItems.slice(halflength, this.allItems.length);
        this.filteredLeftItems = sortBy(this.allItems, (o: any) => o.name);
        // this.filteredRightItems = sortBy(rightItems, (o: any) => o.name);
        this.leftItems = [...this.leftItems, ...this.filteredLeftItems];
        // this.rightItems = this.filteredRightItems;
        if (this.selectAll) {
          this.selectedItems = [];
          this.selectedItems = this.leftItems.map((items: any) => items.id);
        }

        if (this.leftItems.length < itemResp.data.totalCount) {
          this.pageFlag = true;
        }
      }

      setTimeout(() => {
        this.loading = false;
      }, 500);
    },

    async conductItemFilterForLocation(location: any) {
      this.filteredLeftItems = [];
      this.selectedItems = [];
      if (this.selectedLocations.length > 0) await this.getItemsForLocations();
      this.leftItems = [];
      this.rightItems = [];
      if (location.length) {
        location.forEach((locationId: any) => {
          const elements = this.filteredLeftItems.filter((item: any) => {
            return item.locations.some((vl: any) => {
              return vl.id === locationId;
            });
          });
          this.leftItems = [...this.leftItems, ...elements];
          const _elements = this.filteredRightItems.filter((item: any) => {
            return item.locations.some((vl: any) => {
              return vl.id === locationId;
            });
          });
          this.rightItems = [...this.rightItems, ..._elements];
        });
        const temp: any = {};
        this.leftItems.forEach((element: any) => {
          temp[element.id] = element;
        });

        const _temp: any = {};
        this.rightItems.forEach((element: any) => {
          _temp[element.id] = element;
        });
        this.leftItems = Object.values(temp);
        this.rightItems = Object.values(_temp);
      }

      const itemsSelected = this.tax.items.map((item: any) => item.id);
      this.selectedItems = [...new Set(itemsSelected)];
    },

    // private methods
    setIsEditMode(taxId: any): void {
      this.isEditMode = taxId !== undefined && taxId !== null;
    },

    findOrInitializeLocationTax(location: any): any {
      // @ts-ignore
      let taxLocation: any = this.tax.taxLocations.find((taxLocation) => {
        return taxLocation.location && taxLocation.location.id === location.id;
      });
      if (taxLocation === undefined) {
        taxLocation = { location: location, isActive: true };
        this.tax.taxLocations.push(taxLocation);
      }
      return taxLocation;
    },

    findOrInitializeAllLocationTaxes() {
      this.allLocations.forEach((location) => {
        this.findOrInitializeLocationTax(location);
      });
    },

    isValidRange(value, min, max) {
      return !isNaN(value) && value >= min && value <= max;
    },

    disabledBtn() {
      this.isDisabled = true;
    },

    async toggleShowAuditTable() {
      if (!this.changesQueried) {
        this.changes = await this.taxService.getAuditTrailForTax(this.tax.id);
        this.changesQueried = true;
      }
      this.showAuditTable = !this.showAuditTable;
    },

    getAuditButtonVerb() {
      if (this.showAuditTable) return 'Hide';
      else return 'Show';
    },

    handleCancel() {
      if (this.setDiscardChangesModal) {
        this.showDiscardChangesModal = true;
        return;
      }
      this.navigateAway();
    },

    navigateAway() {
      router.push('/taxes');
    },

    async handleSubmit(
      tax: any,
      selectedLocations: any,
      selectedItems: any,
      allItems: any,
      isEditMode: any,
    ) {
      try {
        const word = isEditMode ? 'updated' : 'added';
        if (this.validate) {
          this.loading = true;
          const saveTax = await this.taxService.saveTax(
            tax,
            selectedLocations,
            selectedItems,
            allItems,
            isEditMode,
            this.allLocations,
          );
          let newTax: any = {};
          if (isEditMode) {
            newTax = tax;
          } else {
            tax.id = saveTax?.data.data.tax.id;
            newTax = tax;
          }
          const selectedtaxLocationes = selectedLocations.map(
            (location: any) => {
              const loc = this.allLocations.find((l: any) => l.id === location);
              if (loc) {
                return loc;
              }
            },
          );

          const itemtaxes = {
            taxLocations: JSON.parse(JSON.stringify(selectedtaxLocationes)),
            items: selectedItems,
          };
          await this.taxService.saveItemTaxes(
            newTax,
            itemtaxes,
            this.selectAllItems,
            this.applyAllGoods,
            this.applyAllServices,
            selectedLocations,
            isEditMode,
          );
          this.disabledBtn();
          this.loading = false;
          this.alertTitle = 'success';
          this.alertMessage = `Tax '${tax.name}' ${word} successfully!`;
          this.showConfirm = true;
          this.confirmationService.setFlagValue(false);

          setTimeout(() => {
            router.push('/taxes');
          }, 1500);
        }
      } catch (error) {
        this.loading = false;
        this.activateNotification = true;
        this.typeNotification = 'error';
        this.titleNotification = 'error';
        this.messageNotification =
          'Unable to process your request. Please try again';
      }
    },

    changeTaxItems(event: any) {
      if (event.target.checked) {
        this.selectedItems.forEach((itemId) => {
          const item = this.allItems.find((item) => item.id === itemId);
          // @ts-ignore
          if (item) this.tax.items.push(item);
        });
      } else {
        const itemsIndex = this.tax.items.findIndex(
          (item: any) => event.target.value === item.id,
        );
        this.tax.items.splice(itemsIndex, 1);
      }
    },

    positiveButton() {
      this.showConfirm = false;
    },

    negativeButton() {
      this.showConfirm = false;
    },

    addError(field: any, message: any, error: any) {
      this.formErro[field] = {
        ...this.formErro[field],
        [error]: message,
      };
    },

    removeError(field: any, error?: any) {
      if (isEmpty(this.formErro)) {
        return;
      }
      if (this.formErro[field] && isEmpty(this.formErro[field])) {
        const { [field]: e, ...rest } = this.formErro;
        this.formErro = { ...rest };
        return;
      }
      if (!isEmpty(field) && !isEmpty(error)) {
        const { [error]: e, ...rest } = this.formErro[field];
        this.formErro[field] = { ...rest };
      }
    },
  },
  computed: {
    ...mapGetters(['allTaxes', 'taxFilter']),
    validate() {
      this.formErro = {};
      return (
        this.isValidTaxName &&
        this.isValidTaxValue &&
        this.isAnyTaxLocationSelected
      );
    },

    isAnyTaxLocationSelected() {
      if (this.selectedLocations.length) {
        return true;
      }
      this.addError('location', 'Atleast one location is required', 'required');
      return false;
    },

    isValidTaxName() {
      if (isEmpty(this.tax.name)) {
        this.addError('name', 'Tax name is required', 'required');
        return false;
      }
      return true;
    },

    isValidTaxValue() {
      this.removeError('value');
      let ok = true;
      if (!isEmpty(this.tax.value) && this.tax.value != 0) {
        this.removeError('value', 'required');
        if (this.createTaxDisabled) {
          this.addError('value', 'Tax value is not valid', 'decimal');
          ok = false;
        } else {
          this.removeError('value', 'decimal');
        }

        if (!this.isValidRange(this.tax.value, 1, 99.99)) {
          // range
          this.addError(
            'value',
            'Tax value must be between 1% and 99.99%',
            'range',
          );
          ok = false;
        } else {
          this.removeError('value', 'range');
        }
      } else {
        this.addError('value', 'Tax value is required', 'required');
        ok = false;
      }
      return ok;
    },
  },
  watch: {
    tax: {
      handler() {
        if (!this.taxOriginalRef) {
          return;
        }
        const { items, taxLocations, ...rest } = this.tax;
        const {
          items: refItems,
          taxLocations: refLocations,
          ...ref
        } = this.taxOriginalRef;

        if (!isEqual(toRaw(rest), ref) || !isEqual(items, refItems)) {
          this.setDiscardChangesModal = true;
        } else {
          this.setDiscardChangesModal = false;
        }
      },
      deep: true,
    },

    selectedLocations(newValue, old) {
      if (old.length && !isEqual(toRaw(newValue), old)) {
        this.setDiscardChangesModal = true;
      } else {
        this.setDiscardChangesModal = false;
      }
    },
  },
});
