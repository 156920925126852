
import { defineComponent } from 'vue';

interface ComponentData {
  showTable: boolean;
  buttonVerb: string;
}

export default defineComponent({
  name: 'AuditTrailTable',
  props: ['changes'],
  data() {
    const data: ComponentData = {
      showTable: false,
      buttonVerb: 'Show',
    };
    return data;
  },
  methods: {
    getFields() {
      const fields: Array<any> = [
        { fieldName: { label: 'Field', key: 'fieldName' } },
        {
          fieldPreviousValue: { label: 'Old Value', key: 'fieldPreviousValue' },
        },
        { fieldNewValue: { label: 'New Value', key: 'fieldNewValue' } },
        { modifiedAt: { label: 'Date', key: 'modifiedAt' } },
        { 'modifiedBy.email': { label: 'Modified By' } },
      ];

      if (
        this.changes.length > 0 &&
        Object.prototype.hasOwnProperty.call(this.changes[0], 'type')
      ) {
        fields.unshift({ type: { label: 'Type', key: 'type' } });
      }
      if (
        this.changes.length > 0 &&
        Object.prototype.hasOwnProperty.call(this.changes[0], 'locationName')
      ) {
        fields.unshift({ type: { label: 'Location', key: 'locationName' } });
      }
      if (
        this.changes.length > 0 &&
        Object.prototype.hasOwnProperty.call(this.changes[0], 'user')
      ) {
        fields.unshift({ type: { label: 'Username', key: 'user' } });
      }
      return fields;
    },

    formatAsPercentage(data: number) {
      return (data * 100).toFixed(3) + '%';
    },
  },
});
